import {
    createValidator,
    required,
    email,
    isEmpty,
    integer,
    maxSize,
    phone,
    minSize,
    maxLength,
    minLength,
    isNotBeforeToday,
    isNotAfterToday,
    isNotUnderAge,
} from '../../libs/validation';
import client from '../../libs/apiClient';
import {fields as AuthorityReportingFields} from './Screens/AuthorityReporting/fields';
import moment from 'moment';
import type {
    IsRequired,
    Guest,
    ValidateGuestsErrors,
    Address,
    ValidateAddressesErrors,
    CustomField,
    CustomFieldValidatorField,
} from './types/validation';

const checkSignatureMatch = (name: string) => (value: string) => {
    if (name && value) {
        const cleanName = name.replace(/\s+/, ' ').trim().toLowerCase();
        const cleanValue = value.replace(/\s+/, ' ').trim().toLowerCase();
        if (cleanName !== cleanValue) {
            return `The signature doesn't match your name "${name}"`;
        }
    }
};

const otaEmailWarning = (value: string) => {
    const blacklist = [
        'guest.airbnb.com',
        'guest.booking.com',
        'mchat.booking.com',
        'm.expediapartnercentral.com',
        'messages.homeaway.com',
        'myvr.com',
    ];
    if (value && !isEmpty(value)) {
        if (blacklist.some((domain) => value.toLowerCase().endsWith(domain))) {
            return `We recommend using your personal email for emergencies`;
        }
    }
};

const normalizeEmail = (value: string) => value && `${value}`.trim().toLowerCase();
const normalizePhone = (value: string) => {
    if (value) {
        return value.replace(/[\s.\-()]/g, '');
    }
};
const isCPF = (cpf: string) => {
    cpf = cpf.replace(/[\.\-\/]+/g, '');
    if (cpf === '') {
        return false;
    }
    const invalid = [
        '00000000000',
        '11111111111',
        '22222222222',
        '33333333333',
        '44444444444',
        '55555555555',
        '66666666666',
        '77777777777',
        '88888888888',
        '99999999999',
    ];
    if (cpf.length !== 11 || invalid.includes(cpf)) {
        return false;
    }
    let add = 0;
    for (let i = 0; i < 9; i++) {
        add += parseInt(cpf.charAt(i)) * (10 - i);
    }
    let rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) rev = 0;
    if (rev !== parseInt(cpf.charAt(9))) return false;
    add = 0;
    for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) rev = 0;
    if (rev !== parseInt(cpf.charAt(10))) return false;
    return true;
};

const validateGuests = (isRequired: IsRequired) => (guests: Guest[]) => {
    const errors: ValidateGuestsErrors = {};
    if (isRequired && (!guests || !guests.length)) {
        errors.guests = {_error: 'You must enter all guest information'};
    }
    if (guests && guests.length) {
        const guestArrayErrors: Guest[] = [];
        guests.forEach((guest, index) => {
            const guestErrors: Guest = {};
            if (isRequired) {
                if (!guest || !guest.full_name || isEmpty(guest.full_name)) {
                    guestErrors.full_name = `Required`;
                    guestArrayErrors[index] = guestErrors;
                }
            }
            if (isRequired && isRequired.email) {
                if (!guest || !guest.email || isEmpty(guest.email)) {
                    guestErrors.email = `Required`;
                    guestArrayErrors[index] = guestErrors;
                }
            }
            if (guest && guest.email) {
                const error = email(guest.email);
                if (error) {
                    guestErrors.email = `Invalid email`;
                    guestArrayErrors[index] = guestErrors;
                }
            }
            if (isRequired && isRequired.phone) {
                if (!guest || !guest.phone || isEmpty(guest.phone)) {
                    guestErrors.phone = `Required`;
                    guestArrayErrors[index] = guestErrors;
                }
            }
            if (isRequired && isRequired.birth_date) {
                if (!guest || !guest.birth_date || isEmpty(guest.birth_date)) {
                    guestErrors.birth_date = `Required`;
                    guestArrayErrors[index] = guestErrors;
                }
            }
            if (guest && guest.birth_date) {
                const date = moment(guest.birth_date, 'YYYY-MM-DD');
                if (!date || !date.isValid()) {
                    guestErrors.birth_date = 'Invalid date';
                } else {
                    if (date.isBefore(moment().subtract(120, 'years'))) {
                        guestErrors.birth_date = 'Date cannot be more than 120 years ago';
                    }
                    if (date.isAfter(moment().subtract(18, 'years'))) {
                        guestErrors.birth_date = 'Must be 18 years or older';
                    }
                    if (date.isAfter(moment())) {
                        guestErrors.birth_date = 'Date cannot be in the future';
                    }
                }
                if (guestErrors.birth_date) {
                    guestArrayErrors[index] = guestErrors;
                }
            }
        });
        if (guestArrayErrors.length) {
            errors.guests = guestArrayErrors;
        }
    }
    return errors.guests;
};

const validateAddresses = (addresses: Address[]) => {
    const errors: ValidateAddressesErrors = {};
    if (!addresses || !addresses.length) {
        errors.addresses = {_error: 'You must enter all address information'};
    }
    if (addresses && addresses.length) {
        const addressArrayErrors: Address[] = [];

        // const addressesData = addresses.reduce((acc, curr) => {
        //     const keys = Object.keys(curr || {});
        //     if (keys && keys.length) {
        //         acc[keys[0]] = curr[keys[0]];
        //     }
        //     return acc;
        // }, {});

        addresses.forEach((item, index) => {
            const addressErrors: Address = {};
            if (!item || !item.address || isEmpty(item.address)) {
                addressErrors.address = `Required`;
                addressArrayErrors[index] = addressErrors;
            }
            if (item && item.address && item.address.length < 5) {
                addressErrors.address = `Invalid short address`;
                addressArrayErrors[index] = addressErrors;
            }
            if (!item || !item.city || isEmpty(item.city)) {
                addressErrors.city = `Required`;
                addressArrayErrors[index] = addressErrors;
            }
            if (!item || !item.country || isEmpty(item.country)) {
                addressErrors.country = `Required`;
                addressArrayErrors[index] = addressErrors;
            }
            if (
                item &&
                ['US', 'CA'].includes(item.country || '') &&
                (!item.province_state || isEmpty(item.province_state))
            ) {
                addressErrors.province_state = `Required`;
                addressArrayErrors[index] = addressErrors;
            }
            if (
                item &&
                ['US', 'CA'].includes(item.country || '') &&
                item.province_state &&
                item.province_state.length !== 2
            ) {
                addressErrors.province_state = `Please use state or province two-letter code`;
                addressArrayErrors[index] = addressErrors;
            }
        });
        if (addressArrayErrors.length) {
            errors.addresses = addressArrayErrors;
        }
    }
    return errors.addresses;
};

const backgroundInfoChecks = {
    first_name: [required],
    last_name: [required],
    dob: {
        year: [required, integer],
        month: [required, integer],
        day: [required, integer],
    },
};

const warnGuests = (guests: Guest[] = []) => {
    const guestsErrors: Guest[] = [];
    guests.forEach((guest, i) => {
        const guestErrors: Guest = {};

        if (guest && guest.email && otaEmailWarning(guest.email)) {
            guestErrors.email = otaEmailWarning(guest.email);
        }

        guestsErrors[i] = guestErrors;
    });
    return guestsErrors;
};

const selectCustomFieldsValidator =
    (customFields: CustomField[] = []) =>
    (fields: CustomFieldValidatorField[] = []) => {
        return fields.map((field, i) => {
            const fieldErrors: CustomFieldValidatorField = {};
            const isRequired = (customFields[i] || {}).required;
            if (isRequired && (!field || !field.answer || isEmpty(field.answer))) {
                fieldErrors.answer = `Required`;
            }
            return fieldErrors;
        });
    };

const selectGuestsValidator = (mandatory = false) => {
    return (guests = []) => {
        const allFieldKeys = Object.keys(AuthorityReportingFields)
            .filter((k) => k !== 'identification_document')
            .filter((k) => !k.endsWith('_expiry'))
            .filter((k) => !k.endsWith('_number'));

        // validate content of all fields
        const out = guests.map((guest: {[x: string]: any} = {}, i) => {
            const guestErrors = allFieldKeys.reduce((acc: {[x: string]: string}, key) => {
                // validate content of date fields
                if (
                    guest &&
                    guest[key] &&
                    AuthorityReportingFields[key as keyof typeof AuthorityReportingFields].type === 'date'
                ) {
                    const date = moment(guest[key], 'YYYY-MM-DD');
                    if (!date || !date.isValid()) {
                        acc[key] = 'Invalid date';
                    } else {
                        if ((key || '').endsWith('_expiry') && moment().isAfter(date)) {
                            acc[key] = 'Expiry date must be in the future';
                        }
                        if (key === 'date_of_birth' && !date.isBefore(moment().startOf('day'))) {
                            acc[key] = 'Birth date cannot be current day or in the future';
                        }
                    }
                }

                // validate required fields
                if (i === 0 || mandatory) {
                    // acc[key] = required(guest && guest[key]);
                    if (!guest || !guest[key] || isEmpty(guest[key])) {
                        acc[key] = 'Required';
                    }
                }
                return acc;
            }, {});

            // validate content of other fields
            if (guest && guest.phone && phone(guest.phone)) {
                guestErrors.phone = 'Invalid phone number';
            }
            if (guest && guest.email && email(guest.email)) {
                guestErrors.email = 'Invalid email';
            }
            if (guest && !isEmpty(guest.passport_number) && guest.passport_number.length < 5) {
                guestErrors.passport_number = 'Passport number is too short';
            }
            if (guest && !isEmpty(guest.visa_number) && guest.visa_number.length < 5) {
                guestErrors.visa_number = 'Visa number is too short';
            }
            if (guest && !isEmpty(guest.identity_card_number) && guest.identity_card_number.length < 5) {
                guestErrors.identity_card_number = 'Identity card number is too short';
            }
            if (guest && !isEmpty(guest.drivers_license_number) && guest.drivers_license_number.length < 5) {
                guestErrors.drivers_license_number = 'Drivers license number is too short';
            }
            if (guest && !isEmpty(guest.home_address) && guest.home_address.length < 7) {
                guestErrors.home_address = 'Home address is too short';
            }
            if (guest && !isEmpty(guest.brazil_cpf_number) && !isCPF(guest.brazil_cpf_number)) {
                guestErrors.brazil_cpf_number = 'Invalid CPF number';
            }

            return guestErrors;
        });
        return out;
    };
};

const validGuests = selectGuestsValidator();

const selectSyncValidator = ({mandatoryAuthRep = false, customFields = []}) => {
    const rules = {
        bio: [required],
        category: [required],
        // email: [required, email],
        // experiences: [required],
        full_name: [required],
        // guests: [required, validateGuests(true)],
        phone: [phone],
        signature: [required],

        birth_date: [required, isNotAfterToday, isNotUnderAge],
        address: [required],

        // security deposit
        name_on_card: [required],
        card_address: [required],
        card_country: [required],
        card_postal_code: [required],

        // background and credit checks
        background_check: backgroundInfoChecks,
        credit_check: backgroundInfoChecks,

        // authority reporting
        authority_report: [selectGuestsValidator(mandatoryAuthRep)],

        // custom screen
        custom_screen: [selectCustomFieldsValidator(customFields)],

        // TimeInfoEdit
        arrival_day: [required, integer, minSize(1), maxSize(31), minLength(2), maxLength(2)],
        arrival_month: [required, integer, minSize(1), maxSize(12), minLength(2), maxLength(2)],
        arrival_year: [required, integer, minSize(2010), maxSize(2025), minLength(4), maxLength(4)],
        arrival_hour: [required, integer, minSize(0), maxSize(24), minLength(2), maxLength(2)],
        arrival_minute: [required, integer, minSize(0), maxSize(60), minLength(2), maxLength(2)],

        departure_day: [required, integer, minSize(1), maxSize(31), minLength(2), maxLength(2)],
        departure_month: [required, integer, minSize(1), maxSize(12), minLength(2), maxLength(2)],
        departure_year: [required, integer, minSize(2010), maxSize(2025), minLength(4), maxLength(4)],
        departure_hour: [required, integer, minSize(0), maxSize(24), minLength(2), maxLength(2)],
        departure_minute: [required, integer, minSize(0), maxSize(60), minLength(2), maxLength(2)],
    };
    return createValidator(rules);
};

const SyncValidation = selectSyncValidator({});

const SyncWarning = createValidator({
    email: [otaEmailWarning],
    authority_report: [warnGuests],
});

const AsyncValidation = async (form: {}) => {
    let response;
    try {
        response = await client({
            method: 'POST',
            path: `/guestportal/validate`,
            data: form,
        });
    } catch (error) {
        throw {_error: error.message || error};
    }
    if (response && response.data && response.data.error) {
        throw response.data.error;
    }
    return response.data;
};

export {
    AsyncValidation,
    SyncValidation,
    SyncWarning,
    checkSignatureMatch,
    validateGuests,
    validGuests,
    validateAddresses,
    isEmpty,
    required,
    otaEmailWarning,
    normalizeEmail,
    normalizePhone,
    email,
    phone,
    isNotAfterToday,
    isNotBeforeToday,
    minLength,
    selectSyncValidator,
};
