import moment from 'moment';
import { getOrNull } from '../../../../libs/utils';
import type { FieldType, GetDefaultValuesProps, GetFieldsProps, Guest } from './types/fields.types';

const fields: { [key: string]: FieldType } = {
    first_name: {
        title: 'First name',
        name: 'first_name',
        type: 'text',
        variant: 'standard',
    },
    last_name: {
        title: 'Last name',
        name: 'last_name',
        type: 'text',
        variant: 'standard',
    },
    date_of_birth: {
        title: 'Date of birth',
        name: 'date_of_birth',
        type: 'date',
        openTo: 'year',
        disableFuture: true,
        variant: 'standard',
    },
    gender: {
        title: 'Gender',
        name: 'gender',
        type: 'multichoice',
        choices: [{ text: 'Male' }, { text: 'Female' }, { text: 'Other' }],
    },
    home_address: {
        title: 'Home address',
        name: 'home_address',
        // help: 'HomeAddressHelp',
        type: 'address',
        variant: 'standard',
    },
    // abroad_address: {
    //     title: 'Address abroad',
    //     name: 'abroad_address',
    //     type: 'text',
    //     variant: 'standard',
    // },
    purpose_of_stay: {
        title: 'Purpose of stay',
        name: 'purpose_of_stay',
        type: 'multichoice',
        choices: [{ text: 'Business' }, { text: 'Pleasure' }],
    },
    arrival_date: {
        title: 'Date of arrival',
        name: 'arrival_date',
        type: 'date',
        openTo: 'date',
        variant: 'standard',
    },
    departure_date: {
        title: 'Date of departure',
        name: 'departure_date',
        type: 'date',
        openTo: 'date',
        variant: 'standard',
    },
    phone: {
        title: 'Phone',
        name: 'phone',
        type: 'phone',
        variant: 'standard',
    },
    email: {
        title: 'Email',
        name: 'email',
        type: 'email',
        variant: 'standard',
    },
    signature: {
        title: 'Signature',
        name: 'signature',
        help: 'SignatureHelp',
        type: 'text',
        variant: 'standard',
    },
    nationality: {
        title: 'Nationality',
        name: 'nationality',
        type: 'country',
        variant: 'standard',
    },
    identification_document: {
        title: 'Identification document',
        name: 'identification_document',
        type: 'text',
        variant: 'standard',
    },
    passport_number: {
        title: 'Passport number',
        name: 'passport_number',
        type: 'text',
        variant: 'standard',
    },
    passport_expiry: {
        title: 'Passport expiry date',
        name: 'passport_expiry',
        type: 'date',
        openTo: 'year',
        disablePast: true,
        variant: 'standard',
    },
    visa_number: {
        title: 'Visa number',
        name: 'visa_number',
        help: 'VisaHelp',
        type: 'text',
        variant: 'standard',
    },
    visa_expiry: {
        title: 'Visa expiry date',
        name: 'visa_expiry',
        type: 'date',
        openTo: 'year',
        disablePast: true,
        variant: 'standard',
    },
    identity_card_number: {
        title: 'Identity card number',
        name: 'identity_card_number',
        type: 'text',
        variant: 'standard',
    },
    identity_card_expiry: {
        title: 'Identity card expiry date',
        name: 'identity_card_expiry',
        type: 'date',
        openTo: 'year',
        disablePast: true,
        variant: 'standard',
    },
    drivers_license_number: {
        title: 'Drivers license number',
        name: 'drivers_license_number',
        type: 'text',
        variant: 'standard',
    },
    drivers_license_expiry: {
        title: 'Drivers license expiry date',
        name: 'drivers_license_expiry',
        type: 'date',
        openTo: 'year',
        disablePast: true,
        variant: 'standard',
    },
    brazil_cpf_number: {
        title: 'Brazil CPF number',
        name: 'brazil_cpf_number',
        type: 'text',
        variant: 'standard',
    },
};

const getFields = ({ screenSettings, region, t }: GetFieldsProps) => {
    screenSettings.fields = Object.keys(fields)
        .map((k) => fields[k])
        .filter((f) => !!f);
    if (region) {
        // inject 'nationality' field if 'identification_document' was selected
        const identityIndex = region.fields?.findIndex((f) => f.name === 'identification_document');
        const nationalityIndex = region.fields?.findIndex((f) => f.name === 'nationality');
        if (identityIndex !== -1 && nationalityIndex === -1) {
            region.fields?.splice(identityIndex as number, 0, { name: 'nationality' });
        }

        // get attributes for each field
        screenSettings.fields = region.fields?.filter((f) => !!f).map((f) => fields[f.name]);
    }
    return screenSettings.fields
        ?.filter((f) => f && fields[f.name])
        .map((f) => ({
            ...fields[f.name],
            title: t(f.title),
            choices: f.choices?.map((c) => ({ ...c, label: t(c.label || c.text) })),
            help: f.help ? t(f.help) : null,
        }));
};

const getDefaultValues = ({ reservation, screenSettings, form, guestIndex = 0 }: GetDefaultValuesProps) => {
    screenSettings.fields = screenSettings?.fields || Object.keys(fields).map((k) => fields[k]);
    const genderMap = { '1': 'Male', '2': 'Female' };
    const guests: Guest[] = getOrNull('values.guests', form) || [];
    const nameparts: string[] = ((guests[guestIndex] || {}).full_name || '').split(' ');
    const purpose: string = getOrNull('values.category', form) || getOrNull('form.category', reservation);
    const homeaddr: string = getOrNull('values.id_check.address', form) || getOrNull('values.address', form);
    const dob: string = (
        getOrNull('values.id_check.birth_date', form) ||
        getOrNull('values.birth_date', form) ||
        ''
    ).split('T')[0];
    const docexp: string = (getOrNull('values.id_check.expiry_date', form) || '').split('T')[0];
    const docnum: string = getOrNull('values.id_check.document_number', form) || '';
    const national: string = getOrNull('values.id_check.country_code', form) || '';
    const gender: string | number = getOrNull('values.id_check.sex', form);
    const isPassport = (getOrNull('values.id_check.doc_type', form) || '') === 'passport';
    const primaryNameParts = reservation?.guest_full_name?.split(' ');
    const primaryLastname = primaryNameParts?.[(primaryNameParts?.length || 1) - 1];

    return screenSettings?.fields
        .filter((f) => f && fields[f.name])
        .reduce((acc: { [key: string]: any }, curr) => {
            const key = curr.name.toLowerCase();

            if (key === 'arrival_date') {
                acc[key] = moment(reservation?.check_in_date || '').format('YYYY-MM-DD');
            }
            if (key === 'departure_date') {
                acc[key] = moment(reservation?.check_out_date || '').format('YYYY-MM-DD');
            }
            if (guestIndex === 0) {
                if (key === 'first_name') {
                    acc[key] = primaryNameParts?.[0];
                }
                if (key === 'last_name') {
                    acc[key] = primaryLastname;
                }
                if (key === 'date_of_birth') {
                    acc[key] = dob;
                }
                if (key === 'gender') {
                    acc[key] = genderMap[gender as keyof typeof genderMap];
                }
                if (key === 'passport_expiry' && isPassport) {
                    acc[key] = docexp;
                }
                if (key === 'passport_number' && isPassport) {
                    acc[key] = docnum;
                }
                if (key === 'nationality') {
                    acc[key] = national;
                }
                if (key === 'abroad_address') {
                    acc[key] = reservation?.listing_name;
                }
                if (key === 'home_address') {
                    acc[key] = homeaddr;
                }
                if (key === 'purpose_of_stay') {
                    acc[key] = purpose;
                }
                if (key === 'signature') {
                    acc[key] = getOrNull('values.signature', form) || reservation?.guest_full_name;
                }
                if (key === 'email') {
                    acc[key] =
                        getOrNull('values.email', form) ||
                        getOrNull('form.email', reservation) ||
                        (guests[guestIndex] || {}).email ||
                        reservation?.guest_email;
                }
                if (key === 'phone') {
                    acc[key] =
                        getOrNull('values.phone', form) ||
                        getOrNull('form.phone', reservation) ||
                        (guests[guestIndex] || {}).phone ||
                        reservation?.guest_phone;
                }
            } else {
                if (key === 'first_name') {
                    acc[key] = nameparts[0];
                }
                if (key === 'last_name') {
                    acc[key] = nameparts[nameparts.length - 1];
                }
                if (key === 'email') {
                    acc[key] = (guests[guestIndex] || {}).email;
                }
                if (key === 'phone') {
                    acc[key] = (guests[guestIndex] || {}).phone;
                }
            }
            return acc;
        }, {});
};

export default getFields;
export { fields, getFields, getDefaultValues };

